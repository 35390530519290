import { IoArrowBackOutline, IoEyeOutline } from "react-icons/io5";
import FilterContainer from "components/filter/filter-container";
import { TableObjetivesUserProps } from "../../model";
import style from "../table-process/styles/table-process.module.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "routes/constants";
import Title from "components/fcdt-title/fcdt-title";

const TableObjetives = (props: TableObjetivesUserProps) => {
  const { data, nameProcess, handleNavigate, pathRef } = props;

  return (
    <section className={style.container_table}>
      <div className={`${style.controls_table} fcdt-flex-box`}>
        <div className={style.toggle_table}>
          <span onClick={() => handleNavigate()}>
            <IoArrowBackOutline className={style.icon} size={25} /> Atrás
          </span>
        </div>
        <FilterContainer />
      </div>

      <Title fontSize={18} fontWeight={600} className={style.title_table}>
      Actividades del proceso {nameProcess} {pathRef}
      </Title>

      <div className={style.content}>
        <table>
          <thead>
            <tr>
              <th>Nombre de la actividad</th>
              <th>Descripción</th>
              <th className={style.th_level_action}>Nivel prioridad</th>
              <th className={style.th_level_action}>Estado</th>
              <th className={style.th_level_action}>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((event) => (
              <tr key={event.index}>
                <td>{event.goal_title}</td>
                <td className={style.td_description}>
                  <div>{event.goal_description}</div>
                </td>
                <td>
                  <strong>{event.goal_priority}</strong>
                </td>
                <td>En curso </td>
                <td>
                  <Link
                    className="fcdt-link-to"
                    to={
                      pathRef === ROUTES.DOCUMENT_MANAGEMENT.REF
                        ? `${event.id}/informe`
                        : `${event.id}/tareas`
                    }
                  >
                    <IoEyeOutline className="fcdt-icon fcdt-gray" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default TableObjetives;
