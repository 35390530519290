import FCDTModal from "components/modal-notification/fcdt-modal";
import { ModalDatesProps } from "../../model";
import style from "./style/modal-dates.module.scss";
import Button from "components/buttons/fcdt-button";

const ModalDates = (props: ModalDatesProps) => {
  const {
    handleCloseModal,
    register,
    handleSubmit,
    onSubmit,
    handleChangeLimitDate,
    taskSelected,
    limitDate,
    oldDateCreation
    } = props;
 
  const actualDate = new Date();

  const children = (
    <form className={style.process_container} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.content}>
        <h1>Fechas importantes de la tarea</h1>

        <div className={style.content_left}>
          <div>
            <label htmlFor="date_create">Fecha límite</label>
            <input
              type="date"
              placeholder="Fecha de creación  Día / Mes / Año"
              min={actualDate.toISOString().split("T")[0]}
              required
              {...register("date_limit")}
              onChange={(e) => handleChangeLimitDate(e)}
            />
          </div>

          <div>
            <label htmlFor="date_follow_up">Fecha de seguimiento</label>
            <input
              type="date"
              placeholder="Fecha de seguimiento  Día / Mes / Año"
              min={actualDate.toISOString().split("T")[0]}
              max={limitDate}
              disabled={!limitDate ? true : false}
              required
              {...register("date_follow_up")}
            />
          </div>
        </div>

        <div className={style.content_left}>
          <input
            type="text"
            className={style.input_number_try}
            style={{ width: "710px" }}
            placeholder="Número de intentos"
            min={actualDate.toISOString().split("T")[0]}
            disabled
            {...register("number_tries")}
          />
        </div>
      </div>
      <div className={style.content_buttons}>
        <Button
          className="btn_cancel"
          children="Cancelar"
          width="40"
          type="button"
          onClick={handleCloseModal}
        />
        <Button children="Guardar" width="40" type="submit" />
      </div>
    </form>
  );

  return (
    <FCDTModal open={true} children={children} handleClose={handleCloseModal} />
  );
};

export default ModalDates;
