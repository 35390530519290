import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Title from "components/fcdt-title/fcdt-title";

import { IoCalendarNumberOutline } from "react-icons/io5";
import style from "../styles/body-knowledge.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { ReportKnowledge } from "pages/knowledge/model";
import { setSelectedInformationReports } from "store/slices/knowledge";
import { formatDate } from "helpers/formatDate";

const ReportsList = () => {
  const dispatch = useDispatch();

  const { reportsInformation } = useSelector((state: RootState) => {
    return state.knowledge;
  });

  const handleSelectMeeting = (report: ReportKnowledge) => {
    dispatch(setSelectedInformationReports(report));
  };

  return (
    <div>
      {reportsInformation.length === 0 ? (
        <Paragraph fontWeight={300} className={style.container_not_results}>
          No se encontraron resultados
        </Paragraph>
      ) : (
        <>
          {reportsInformation.map((report) => (
            <div
              key={report.id_report}
              className={style.container_children_accordion}
              onClick={() => handleSelectMeeting(report)}
              style={{
                backgroundColor: report.isSelected
                  ? "rgba(0, 222, 151, 0.2)"
                  : "",
              }}
            >
              <Paragraph className={style.paragrap_titles} fontWeight={500}>
                <Title fontSize={16} fontWeight={500}>
                  Actividad:{" "}
                </Title>
                {report.process_goal_name}
              </Paragraph>
              <div className={style.container_calendar_description_reports}>
                <IoCalendarNumberOutline size={25} className={style.icon} />{" "}
                <div>
                  <Paragraph
                    fontSize={14}
                    fontWeight={300}
                    className={style.paragrap_calendar}
                  >
                    <Title fontSize={14} fontWeight={600}>
                      Periodo de consulta:{" "}
                    </Title>
                    {formatDate(report.start_date)} a{" "}
                    {formatDate(report.end_date)}
                  </Paragraph>
                  <Paragraph
                    fontSize={14}
                    fontWeight={300}
                    className={style.paragrap_calendar}
                  >
                    <Title fontSize={14} fontWeight={600}>
                      Generado:{" "}
                    </Title>
                    {formatDate(report.generation_date)}{" "}
                    <Title fontSize={14} fontWeight={600}>
                      por:{" "}
                    </Title>
                    <strong>{report.created_by}</strong>
                  </Paragraph>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ReportsList;
