import { IoMdClose } from "react-icons/io";
import style from "../style/newgoal.module.scss";
import Button from "components/buttons/fcdt-button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FormNewGoal } from "../model";
import { useForm } from "react-hook-form";
import { RootState } from "store/store";
import {
  createGoal,
  deleteGoal,
  getGoals,
  setDeleteGoal,
  setOpenNewGoal,
  setisOpenNewProcess,
  updateGoal,
} from "pages/admin/store/slices/process";
import { IoCloseOutline, IoTrashOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import Dialog from "../dialog";
import { ConfirmationMessage } from "./confirmation-message";
import { setConfirmationMessage } from "store/slices/superAdmin";
import { Colaborator } from "pages/collaborator/components/process/model";
import { Autocomplete, TextField } from "@mui/material";
import { REG_EXP, TITLE_REG_EXP } from "common/contants";
export const NewGoal = () => {
  const dispatch = useDispatch<any>();
  const { register, handleSubmit, reset } = useForm<FormNewGoal>();

  const [goals, setGoals] = useState<FormNewGoal[]>([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedGoal, setSelectedGoal] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [auxiIsDeleteGoal, setAuxiIsDeleteGoal] = useState(true);

  const [dataColaboratorSelected, setdataColaboratorSelected] = useState<
    Colaborator[]
  >([]);

  const { isOpenConfirmationMessage } = useSelector((state: RootState) => {
    return state.superadmin;
  });
  const { isDeleteGoal, allColaborators, isOpenNewProcess } = useSelector(
    (state: RootState) => {
      return state.admin.process;
    }
  );
  if (isDeleteGoal && auxiIsDeleteGoal) {
    toast.success("Debes seleccionar las actividad a eliminar, asegurate que sean las correctas.", { duration: 7000, position: "top-center" });
    setAuxiIsDeleteGoal(false)
  }

  const { currentDate } = useSelector((state: RootState) => {
    return state.auth;
  });

  const handleCloseDialog = () => {
    dispatch(setOpenNewGoal(false));
    dispatch(setisOpenNewProcess(!isOpenNewProcess));
    dispatch(setDeleteGoal(false));
    reset();
  };
  const handleCloseConfirmationMessage = () => {
    dispatch(setConfirmationMessage(!isOpenConfirmationMessage));
  };
  const handleDeleteGoal = () => {
    dispatch(setDeleteGoal(true));
    setSelectedOption(null);
    setSelectedGoal(false);
  };
  const handleChangeAutocompleted = (e: any, newData: any) => {
    const value = e.target.innerText;
    let id =newData.uid
    const filterFilms = allColaborators.find((item) => item.uid === id);
    if (!filterFilms) return;
    if (value) {
      let newData = [...dataColaboratorSelected];
      const filterRes = newData.find((item) => item.label === value);
      if (filterRes) return;
      newData.push({ label: filterFilms.label, uid: filterFilms.uid });
      setdataColaboratorSelected(newData);
    }
  };

  // const handleChangeAutocompleted = (e: ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   console.log(value);

  //   const filterFilms = allColaborators.find((item) => item.label === value);
  //   if (!filterFilms) return;
  //   if (value) {
  //     let newData = [...dataColaboratorSelected];
  //     const filterRes = newData.find((item) => item.label === value);
  //     if (filterRes) return;
  //     newData.push({ label: filterFilms.label, uid: filterFilms.uid });
  //     setdataColaboratorSelected(newData);
  //   }
  // };

  const handleUpdate = (goal: any) => {
    setSelectedOption(goal.goal.id === selectedOption ? null : goal.goal.id);
    setSelectedGoal(true);

    reset({
      id: goal.goal?.id,
      id_process: goal.goal.id_process,
      goal_title: goal.goal.goal_title,
      goal_description: goal.goal.goal_description,
      id_goal_priority: goal.goal.id_goal_priority,
      expiration_date: goal.goal.expiration_date.substring(0, 10),
      collaborator: goal.collaborators[0]?.id_person?._id,
      collaborator_name: goal.collaborators[0]?.id_person?.name
        ? goal.collaborators[0]?.id_person?.name
        : "",
    });

    setdataColaboratorSelected(
      goal.collaborators.map((item: any) => {
        return { uid: item.uid, label: item.name_in_meeting_platform };
      })
    );
  };

  const handleDeleteColaborator = (colaborator: Colaborator) => {
    const newData = dataColaboratorSelected.filter(
      (item) => item.uid !== colaborator.uid
    );
    setdataColaboratorSelected(newData);
  };

  const onSubmit = async (data: FormNewGoal) => {
    if (dataColaboratorSelected.length === 0) {
      toast.error("Agregar al menos un participante a la actividad", { duration: 7000, position: "top-center" });
      return;
    }
    setCreateSuccess(false);
    const response =
      selectedOption !== null
        ?

        await dispatch(
          updateGoal(
            data,
            dataColaboratorSelected.map((item) => item.uid)
          )
        )
        : isDeleteGoal
          ? await dispatch(deleteGoal(data))
          : await dispatch(
            createGoal(
              data,
              dataColaboratorSelected.map((item) => item.uid)
            )
          );

    if (!response?.ok) {
      toast.error(response?.msg, { duration: 7000, position: "top-center" });
      return;
    }

    if (response?.ok) {
      toast.success(response?.msg, { duration: 7000, position: "top-center" });
      setCreateSuccess(true);
      dispatch(setConfirmationMessage(false));
      reset({
        goal_title: "",
        goal_description: "",
        expiration_date: "",
        id_goal_priority: 0,
        collaborator: "",
        collaborator_name: "",
        goalsFromProcess: [],
      });
      setdataColaboratorSelected([]);
    }
  };
  useEffect(() => {
    const fetchGoals = async () => {
      const response = await dispatch(getGoals());
      setGoals(response?.goalsFromProcess);
    };
    fetchGoals();
    if (selectedOption === null) {
      setSelectedGoal(false);
      reset({
        goal_title: "",
        goal_description: "",
        expiration_date: "",
        id_goal_priority: 0,
        collaborator: "",
        collaborator_name: "",
      });
    }
  }, [selectedOption, createSuccess]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.new_goal_container}>
        <div className={style.header}>
          <h2>
            {!selectedGoal && !isDeleteGoal
              ? "Nueva"
              : isDeleteGoal
                ? "Eliminar"
                : "Actualizar"}{" "}
            actividad
          </h2>
          <button type="button" onClick={handleCloseDialog}>
            {" "}
            <IoMdClose size={35} color="#2f0084" />{" "}
          </button>
        </div>
        <div className={style.content}>
          <div className={style.content_left}>
            <input
              type="text"
              className="fcdt-input"
              placeholder="Nombre de la actividad"
              required
              minLength={6}
              maxLength={250}
              pattern={REG_EXP.PROCESS_NAME}
              title={TITLE_REG_EXP.PROCESS_NAME}
              {...register("goal_title")}
              disabled={isDeleteGoal}
            />
            <input
              type="text"
              className="fcdt-input"
              placeholder="Descripción"
              required
              minLength={6}
              maxLength={500}
              pattern={REG_EXP.PROCESS_DESCRIPTIONS}
              title={TITLE_REG_EXP.PROCESS_NAME}
              {...register("goal_description")}
              disabled={isDeleteGoal}
            />
            <select
              className="fcdt-select"
              required
              {...register("id_goal_priority")}
              disabled={isDeleteGoal}
            >
              <option disabled value={0}>
                Nivel de prioridad
              </option>
              <option value={1}>Urgente</option>
              <option value={2}>Importante</option>
              <option value={3}>Media</option>
              <option value={4}>Baja</option>
            </select>
            <label htmlFor="date">Fecha limite de ejecución</label>
            <input
              id="date"
              type="date"
              className="fcdt-input"
              placeholder="Fecha limite de ejecución"
              min={!selectedGoal && !isDeleteGoal?currentDate:""}
              required
              {...register("expiration_date")}
              disabled={isDeleteGoal}
            />
          </div>
          <div className={style.content_right}>
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allColaborators}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Añadir responsables" />
              )}
              value={{ label: "", uid: "" }}
              onSelect={handleChangeAutocompleted}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "onClick") {
                  e.preventDefault();
                }
              }}
            /> */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allColaborators}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Añadir responsables" />
              )}
              onChange={handleChangeAutocompleted}
              value={{ label: "", uid: "" }}
            />
            <ul>
              {
                // selectedGoal?
                dataColaboratorSelected.map((colaborator, index) => {
                  return (
                    <li key={index}>
                      <span className="fcdt-title-responsive">
                        {colaborator.label}
                      </span>{" "}
                      <IoCloseOutline
                        className="fcdt-icon"
                        onClick={() => handleDeleteColaborator(colaborator)}
                      />
                    </li>
                  );
                })
                // :""
              }
            </ul>
          </div>
          <div className={style.content_goals}>
            <h3>Actividades creadas</h3>
            <ul>
              {goals?.map((item) => (
                <li key={item.goal?.id}>
                  {isDeleteGoal ? (
                    <input
                      type="checkbox"
                      id={`${item.goal?.id}`}
                      {...register("goalsFromProcess", { required: false })}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      id={item.goal?.id}
                      value={item.goal?.id}
                      checked={selectedOption === item.goal?.id}
                      onClick={() => handleUpdate(item)}
                      {...register("goalsFromProcess", { required: false })}
                    />
                  )}
                  <p>{item.goal?.goal_title}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={style.content_buttons}>
          {selectedGoal ? (
            <>
              <button
                type="button"
                className="fcdt_btn_primary fcdt-btn_update"
                onClick={() => handleDeleteGoal()}
              >
                {" "}
                <IoTrashOutline size={28} color="#2f0084" /> Eliminar
              </button>
              <Button children="Guardar" width="30" disabled={isDeleteGoal} />
            </>
          ) : !isDeleteGoal ? (
            <>
              <Button
                className="btn_cancel"
                children="Cancelar"
                width="30"
                onClick={handleCloseDialog}
              />
              <Button children={"Añadir"} width="30" />
            </>
          ) : (
            <>
              <button
                type="button"
                className="fcdt_btn_primary fcdt-btn_update"
                onClick={() => {
                  dispatch(setConfirmationMessage(!isOpenConfirmationMessage))
                }}
              >
                {" "}
                <IoTrashOutline size={28} color="#2f0084" /> Eliminar
              </button>

              <Button
                type="button"
                className="fcdt_btn_primary"
                children="Guardar"
                width="30"
                disabled={isDeleteGoal}
              />
            </>
          )}
        </div>
      </div>
      <Dialog
        isOpen={isOpenConfirmationMessage}
        onClose={handleCloseConfirmationMessage}
      >
        <ConfirmationMessage message="actividad" />
      </Dialog>
    </form>
  );
};
