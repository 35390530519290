import Paragraph from "components/fcdt-paragraph/fcdt-paragraph";
import Title from "components/fcdt-title/fcdt-title";

import { IoCalendarNumberOutline } from "react-icons/io5";
import style from "../styles/body-knowledge.module.scss";
import Button from "components/buttons/fcdt-button";
import { ReportKnowledge } from "pages/knowledge/model";
import { formatDate } from "helpers/formatDate";
import { useDispatch } from "react-redux";
import { setShowGantt } from "store/slices/knowledge";

const ReportsDescription = (props: { reportSelected: ReportKnowledge }) => {
  const dispatch = useDispatch();

  const { reportSelected } = props;

  return (
    <div className={style.container_details_accordion}>
      <Paragraph
        className={style.paragrah_container}
        fontWeight={400}
        fontSize={18}
      >
        <Title fontSize={18} fontWeight={600}>
          Actividad:
        </Title>
        {reportSelected.process_goal_name}
      </Paragraph>

      <Paragraph
        className={`${style.paragrah_container} ${style.paragrah_containe_start}`}
        fontSize={14}
        fontWeight={500}
      >
        <IoCalendarNumberOutline size={22} />
        <Title fontSize={14}>
          <strong> Periodo comprendido: </strong>
          <span>
            {formatDate(reportSelected.start_date)} a{" "}
            {formatDate(reportSelected.end_date)}
          </span>
        </Title>
      </Paragraph>

      <div className={style.container_dashed}></div>

      <Title fontSize={16} fontWeight={600}>
        Descripción de la actividad
      </Title>
      <Paragraph fontWeight={300} fontSize={14}>
        {reportSelected.process_goal_description}
      </Paragraph>

      <div className={style.container_dashed}></div>

      <Title fontSize={16} fontWeight={600}>
        Información sobre reuniones
      </Title>
      <Paragraph fontWeight={300} fontSize={14}>
        {reportSelected.report_data.info_from_minutes.content}
      </Paragraph>

      <div className={style.container_dashed}></div>

      <Title fontSize={16} fontWeight={600}>
        Información sobre tareas
      </Title>

      {reportSelected.report_data.info_from_tasks.length === 0 ? (
        <Paragraph fontSize={14} fontWeight={300}>No se encontraron temas tratados</Paragraph>
      ) : (
        <Button
          className={`fcdt-btn_update ${style.button_gantt}`}
          onClick={() => dispatch(setShowGantt(true))}
        >
          Ver diagrama de Gantt
        </Button>
      )}
    </div>
  );
};

export default ReportsDescription;
