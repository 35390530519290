import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AllDataInfoColaborator,
  Colaborator,
  FormAddDates,
  FormAddTaks,
  GoalsTable,
  ProcesosCollaboratorLeader,
  ProcessTable,
  RequestCreateTask,
  ResponseCheckList,
  ResponseGolasColaborator,
  ResponseInformationTask,
  ResponseProcessesColaborator,
  TaskChecklist,
  TaskDetails,
  TasksByGoal,
} from "pages/collaborator/components/process/model";
import { displayLoader, removeProcess } from "store/slices/loading";
import { AppThunk } from "store/store";
import * as Api from "pages/collaborator/components/process/api/api";
import { handlePriority } from "pages/collaborator/components/process/helpers";
import { formatDate } from "helpers/formatDate";
import { DataToggle } from "components/fcdy-toggle/model";
import { SubTaskts } from "pages/document-management/components/documents/components/recommended-task/components/modal-add_task/model";
import { uid } from "uid";

type InitialState = {
  isFeching: boolean;
  isReloadNeeded: boolean;
  dataTableProcess: ProcessTable[];
  dataGolas: GoalsTable[];
  nameProcess: string;
  dataAllTaskByIdGoal: TasksByGoal[];
  taskSelected?: TasksByGoal;
  statusTask: DataToggle;
  dataAllColaborator: Colaborator[];
  dataColaboratorSelectedRef: Colaborator[];
  isModalDates: boolean;
  isModalDeleteTask: boolean;
  datesCalendar?: FormAddDates;
  datesCalendarRef?: FormAddDates;
  descriptionDeleteUpdateTask: string;
  isEditTask: boolean;
  subtasks: SubTaskts[];
};
const initialState: InitialState = {
  isFeching: false,
  isReloadNeeded: true,
  dataTableProcess: [],
  dataGolas: [],
  nameProcess: "",
  dataAllTaskByIdGoal: [],
  taskSelected: undefined,
  statusTask: {
    title: "Cancelar tarea",
    titleActive: "Activo",
    active: true,
  },
  dataAllColaborator: [],
  dataColaboratorSelectedRef: [],
  isModalDates: false,
  isModalDeleteTask: false,
  datesCalendar: undefined,
  descriptionDeleteUpdateTask: "",
  isEditTask: false,
  subtasks: [],
};
export const slice = createSlice({
  name: "process",
  initialState,
  reducers: {
    setProcessUsersInit: (state) => {
      state.isReloadNeeded = true;
      state.isFeching = true;
    },
    setProcessUsersSuccess: (
      state,
      actions: PayloadAction<ProcesosCollaboratorLeader[]>
    ) => {
      state.dataTableProcess = actions.payload.map((item, index) => {
        return {
          id: index + 1,
          _id: item.uid,
          process_name: item.process_name,
          descriptive_paragraph: item.descriptive_paragraph,
        };
      });
      state.isFeching = false;
      state.isReloadNeeded = false;
    },
    setGoalsUsersSuccess: (
      state,
      actions: PayloadAction<ResponseGolasColaborator>
    ) => {
      state.dataGolas = actions.payload.goalsFromProcess.map((goal, index) => {
        const item = goal.goal;
        return {
          index: index + 1,
          id: item.id,
          goal_title: item.goal_title,
          goal_description: item.goal_description,
          goal_priority: handlePriority(item.id_goal_priority),
          goal_status: "En curso",
        };
      });
      state.nameProcess = actions.payload.process_info.process_name;
      state.isFeching = false;
      state.isReloadNeeded = false;
    },
    setInformationTaskSuccess: (state, action: PayloadAction<TaskDetails>) => {
      state.dataColaboratorSelectedRef = [];

      state.dataColaboratorSelectedRef = action.payload.collaborators.map(
        (item) => {
          return { label: item.id_person.name, uid: item.uid };
        }
      );

      const {
        task_start_date,
        task_due_date,
        task_probable_date,
        task_optimistic_date,
        attempt_number,
      } = action.payload.taskDates;
      const {
        meeting_date
      } = action.payload.taskReviewMeeting;

      const dates: FormAddDates = {
        date_creation: formatDate(task_start_date),
        date_limit: formatDate(task_due_date),
        date_likely: formatDate(task_probable_date),
        date_optimistic: formatDate(task_optimistic_date),
        date_follow_up: formatDate(meeting_date),
        number_tries: attempt_number.toString(),
      };

      state.datesCalendar = dates;
      state.datesCalendarRef = dates;
    },
    setResetCollaboratorRef: (state) => {
      state.dataColaboratorSelectedRef = [];
    },
    setAllTaskByIdGoal: (state, action: PayloadAction<TasksByGoal[]>) => {
      state.dataAllTaskByIdGoal = action.payload;
    },
    setSelectTask: (state, action: PayloadAction<TasksByGoal | undefined>) => {
      state.taskSelected = action.payload;

      state.dataAllTaskByIdGoal = state.dataAllTaskByIdGoal.map((task) => {
        if (action.payload) {
          if (task.uid === action.payload.uid) {
            if (task.isSelected) {
              state.taskSelected = undefined;
            }
            state.statusTask.active =
              task.execution_status !== 6 ? true : false;
            return { ...task, isSelected: !task.isSelected };
          }
        }
        return { ...task, isSelected: false };
      });
    },
    setStatusToggle: (state, action: PayloadAction<boolean>) => {
      state.statusTask.active = action.payload;
    },
    setAllColaborator: (
      state,
      actions: PayloadAction<AllDataInfoColaborator[]>
    ) => {
      state.dataAllColaborator = actions.payload.map((colaborator) => {
        return {
          label: colaborator.name_in_meeting_platform,
          uid: colaborator.uid,
        };
      });
    },
    setDatesCalendar: (
      state,
      actions: PayloadAction<FormAddDates | undefined>
    ) => {
      state.datesCalendar = actions.payload;
    },
    setDescriptionDeleteTask: (state, action: PayloadAction<string>) => {
      state.descriptionDeleteUpdateTask = action.payload;
    },
    setIsEditTask: (state, action: PayloadAction<boolean>) => {
      state.isEditTask = action.payload;
    },
    setReloadProcessUsers: (state) => {
      state.isReloadNeeded = true;
    },
    setIsModalDates: (state, action: PayloadAction<boolean>) => {
      state.isModalDates = action.payload;
    },
    setIsModalDeleteUpdateTask: (state, action: PayloadAction<boolean>) => {
      state.isModalDeleteTask = action.payload;
    },
    setSubtasksSucess: (state, action: PayloadAction<TaskChecklist[]>) => {
      state.subtasks = action.payload.map((item) => {
        return {
          id: item.uid,
          name: item.checklist_description,
          isEdit: false,
        };
      });
    },
    setAddEditCloseDeleteSubTask: (
      state,
      actions: PayloadAction<{
        service: number;
        value: string;
        task?: SubTaskts;
      }>
    ) => {
      //1. Add, 2. Edit, 3. Edit-Close, 4. Delete
      if (actions.payload.service === 1) {
        const subtask: SubTaskts = {
          id: uid(),
          name: actions.payload.value,
          isEdit: false,
        };
        state.subtasks = [...state.subtasks, subtask];
      } else if (actions.payload.service === 2 && actions.payload.task) {
        state.subtasks = state.subtasks.map((item) => {
          if (item.id === actions.payload.task?.id) {
            return { ...item, name: actions.payload.value };
          }
          return item;
        });
      } else if (actions.payload.service === 3 && actions.payload.task) {
        state.subtasks = state.subtasks.map((item) => {
          if (item.id === actions.payload.task?.id) {
            return { ...item, isEdit: !item.isEdit };
          }
          return { ...item, isEdit: false };
        });
      } else {
        state.subtasks = state.subtasks.filter(
          (item) => item.id !== actions.payload.task?.id
        );
      }
    },
    setResetSubTask: (state) => {
      state.subtasks = [];
    },
  },
});
export const {
  setReloadProcessUsers,
  setIsModalDates,
  setIsModalDeleteUpdateTask,
  setSelectTask,
  setDatesCalendar,
  setDescriptionDeleteTask,
  setResetCollaboratorRef,
  setStatusToggle,
  setIsEditTask,
  setAddEditCloseDeleteSubTask,
  setResetSubTask,
} = slice.actions;
export default slice.reducer;

export const getProcessesName = (): AppThunk => async (dispatch, getState) => {
  const idProcess: string = dispatch(displayLoader());
  interface objectProcces {
    descriptive_paragraph: string;
    id_company: string;
    process_leader: string;
    process_name: string;
    status: boolean;
    uid: string;
  }
  try {
    dispatch(slice.actions.setProcessUsersInit());
    const { token, collaborator } = getState().auth.dataUser!;
    const response: ResponseProcessesColaborator = await Api.getProcessesName(
      token
    );
    const listFilterProcess = response.procesosAsCollaborator?.filter((item): item is objectProcces => item !== null && item !== undefined);
    dispatch(
      slice.actions.setProcessUsersSuccess(
        collaborator.is_leader ? response.procesosAsLeader
          // :response.procesosAsCollaborator
          : listFilterProcess
      )
    );
    dispatch(removeProcess(idProcess));
    return true;
  } catch (error: any) {
    dispatch(removeProcess(idProcess));
    return false;
  }
};

export const getColaboratorGolas =
  (id_Process: string): AppThunk =>
    async (dispatch, getState) => {
      const idProcess: string = dispatch(displayLoader());
      try {
        dispatch(slice.actions.setProcessUsersInit());
        const { token } = getState().auth.dataUser!;
        const response: ResponseGolasColaborator = await Api.getColaboratorGolas(
          id_Process,
          token
        );
        dispatch(slice.actions.setGoalsUsersSuccess(response));
        dispatch(removeProcess(idProcess));
        return true;
      } catch (error: any) {
        dispatch(removeProcess(idProcess));
        return false;
      }
    };

export const getAllTaskByIdGoal =
  (idGoal: string): AppThunk =>
    async (dispatch, getState) => {
      const idProcess: string = dispatch(displayLoader());
      try {
        dispatch(slice.actions.setProcessUsersInit());
        const { token, collaborator } = getState().auth.dataUser!;
        const responseAllTask: TasksByGoal[] = await Api.getAllTaskByIdGoal(
          idGoal,
          token, 0, collaborator.uid
        );

        dispatch(
          slice.actions.setAllTaskByIdGoal(
            // responseAllTask?.length ? responseAllTask : []
            []

          )
        );
        dispatch(removeProcess(idProcess));
        return true;
      } catch (error: any) {
        dispatch(removeProcess(idProcess));
        return false;
      }
    };

export const getAllTaskByIdGoalAndColaborators =
  (idGoal: string): AppThunk =>
    async (dispatch, getState) => {
      const idProcess: string = dispatch(displayLoader());
      try {
        dispatch(slice.actions.setProcessUsersInit());
        const { token, collaborator } = getState().auth.dataUser!;
        const { is_admin, is_leader } = getState().auth.dataUser?.collaborator!;
        const [tasks] = await Promise.all([
          Api.getTaskByuser(collaborator.uid, token)

        ]);
        const [responseAllTask, responseAllColaborator]: [
          TasksByGoal[],
          AllDataInfoColaborator[]
        ] = await Promise.all([
          Api.getAllTaskByIdGoal(idGoal, token, is_admin || is_leader ? 1 : 0, collaborator.uid),
          Api.getAllColaborators(idGoal, token),
        ]);
        const listTasks = tasks.tasks.flat();
        const responseTask = is_admin || is_leader ? responseAllTask : listTasks
        dispatch(
          slice.actions.setAllTaskByIdGoal(responseAllTask)
        );
        dispatch(slice.actions.setAllColaborator(responseAllColaborator));
        dispatch(removeProcess(idProcess));
        return true;
      } catch (error: any) {
        dispatch(removeProcess(idProcess));
        return false;
      }
    };

export const createdTask =
  (form: FormAddTaks, idGoal: string, collaborators: string[]): AppThunk =>
    async (dispatch, getState) => {
      const idProcess: string = dispatch(displayLoader());
      try {
        dispatch(slice.actions.setProcessUsersInit());
        const { token, collaborator } = getState().auth.dataUser!;
        const { currentDate } = getState().auth;
        const {
          datesCalendar,
          taskSelected,
          descriptionDeleteUpdateTask,
          statusTask,
          isEditTask,
          subtasks,
        } = getState().colaborator.process;

        const newSubTask: string[] = [];

        subtasks.forEach((item) => newSubTask.push(item.name));

        const body: RequestCreateTask = {
          task_name: form.taks_name,
          task_description: form.task_description,
          task_priority: parseInt(form.task_priority),
          execution_status: form.task_state ? parseInt(form.task_state) : 1,
          associated_goal: idGoal,
          assignated_by: collaborator.uid,
          collaborators,
          task_start_date: currentDate + "T00:00:00Z",
          task_due_date: datesCalendar?.date_limit! + "T00:00:00Z",
          task_optimistic_date: datesCalendar?.date_limit! + "T00:00:00Z",
          task_probable_date: datesCalendar?.date_limit! + "T00:00:00Z",
          meeting_date: datesCalendar?.date_follow_up! + "T00:00:00Z",
          task_cancellation_reason: !isEditTask
            ? descriptionDeleteUpdateTask
            : "",
          task_modification_reason: isEditTask ? descriptionDeleteUpdateTask : "",
          task_checklists: newSubTask,
        };
        const response: ResponseGolasColaborator = !taskSelected
          ? await Api.createdTask(body, token)
          : await Api.updatedTask(
            body,
            taskSelected.uid,
            parseInt(form.task_state),
            statusTask.active,
            token
          );
        dispatch(removeProcess(idProcess));
        return response;
      } catch (error: any) {
        console.log({ error });
        dispatch(removeProcess(idProcess));
        return false;
      }
    };

export const getInformationTask =
  (idTask: string): AppThunk =>
    async (dispatch, getState) => {
      const idProcess: string = dispatch(displayLoader());
      try {
        dispatch(slice.actions.setProcessUsersInit());
        const { token } = getState().auth.dataUser!;
        const [responseTask, responseSubTask]: [
          ResponseInformationTask,
          ResponseCheckList
        ] = await Promise.all([
          Api.getInformationTask(idTask, token),
          Api.getInformationSubTask(idTask, token),
        ]);
        if (responseSubTask.taskChecklists) { dispatch(slice.actions.setSubtasksSucess(responseSubTask.taskChecklists)); }
        if (responseTask.taskDetails) { dispatch(slice.actions.setInformationTaskSuccess(responseTask.taskDetails)); }

        dispatch(removeProcess(idProcess));
        return true;
      } catch (error: any) {

        console.log(error)
        dispatch(removeProcess(idProcess));
        return false;
      }
    };

// export const deleteTask = (): AppThunk => async (dispatch, getState) => {
//   const idProcess: string = dispatch(displayLoader());
//   try {
//     dispatch(slice.actions.setProcessUsersInit());
//     const { token } = getState().auth.dataUser;
//     const { taskSelected } = getState().colaborator.process;

//     const response: any = await Api.deleteTask(taskSelected!, token);
//     dispatch(removeProcess(idProcess));
//     return response;
//   } catch (error: any) {
//     dispatch(removeProcess(idProcess));
//     return false;
//   }
// };
