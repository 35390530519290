import { IoSearch } from "react-icons/io5";
import { FilterProps } from "./model";
import style from "./style/filter.module.scss";

const Filter = (props: FilterProps) => {
  const { filter, setFilter, onSubmit } = props;
  return (
    <form onSubmit={onSubmit} className={style.filter_form}>
      <label>
        <IoSearch size={32} />
        <input
          type="text"
          onChange={(e) => setFilter(e)}
          defaultValue={filter.search}
          placeholder="Buscar por categoría"
        />
      </label>
    </form>
  );
};

export default Filter;
