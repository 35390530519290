import { IoEyeOutline } from "react-icons/io5";
import FilterContainer from "components/filter/filter-container";
import { TableProcessUserProps } from "../../model";
import style from "./styles/table-process.module.scss";
import { Link } from "react-router-dom";
import Title from "components/fcdt-title/fcdt-title";

const TableProcess = (props: TableProcessUserProps) => {
  const { data } = props;

  return (
    <section className={style.container_table}>
      <div className={`${style.controls_table} fcdt-flex-box`}>
        <div className={style.toggle_table}></div>
        <FilterContainer />
      </div>

      <Title fontSize={18} fontWeight={600} className={style.title_table}>
        Lista de procesos asignados
      </Title>

      <div className={style.content}>
        <table>
          <thead>
            <tr>
              <th>Nombre del proceso</th>
              <th>Descripción</th>
              <th className={style.th_level_action}>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((event) => (
              <tr key={event.id}>
                <td>{event.process_name}</td>
                <td className={style.td_description}>
                  <div>{event.descriptive_paragraph}</div>
                </td>

                <td>
                  <Link className="fcdt-link-to" to={`${event._id}/metas`}>
                    <IoEyeOutline className="fcdt-icon fcdt-gray" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default TableProcess;
