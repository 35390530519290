import style from "./style/sidebar.module.scss";
import Logo from "components/logo/fcdt-logo";
import {
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoLogInOutline,
} from "react-icons/io5";
import LinkNav from "components/link-nav/link-nav";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SideBarProps } from "./model";

import { useState } from "react";
import { setDataUser } from "store/slices/auth";
import { RootState } from "store/store";

const Sidebar = (props: SideBarProps) => {
  const location = useLocation();
  const { pathname } = location;

  const dispatch = useDispatch<any>();

  const [isSelect, setisSelect] = useState(false);
  const { dataUser } = useSelector((state: RootState) => {
    return state.auth;
  });

  const authority = dataUser?.collaborator.is_leader || dataUser?.collaborator.is_admin;
  
  const handleShowSelect = () => {
    setisSelect(!isSelect);
  };

  const handleCloseAccordion = () => {
    setisSelect(false);
  };

  const logOutUser = () => {
    window.location.href = "/";
    dispatch(setDataUser(undefined));
    localStorage.clear();
  };

  return (
    <aside className={style.sidebar}>
      <Logo width={180} />
      <br />
      <nav>
        {props.sideBarLink.map((link, index) => {
          return (
            <article key={index}
              style={{ display: !authority && index > 3 ? "none" : "block" }}>
              {!link.isSelect ? (
                <div style={{ display: !authority && index === 1 ? "none" : "block" }}>
                  <LinkNav
                    key={link.url}
                    url={link.url}
                    isActive={pathname.includes(link.url)}
                    isDisabled={link.isDisabled}
                  >
                    <div onClick={handleCloseAccordion}>
                      <link.icon size={24} />
                      {link.label}
                    </div>
                  </LinkNav>
                </div>
              ) : (
                <>
                  <div
                    className={link.isDisabled ? `${style.disabled}` : ""}
                    onClick={handleShowSelect}
                  >
                    <link.icon size={24} />
                    {link.label}
                    {!isSelect ? (
                      <IoChevronDownOutline
                        className="fcdt-icon-green"
                        size={30}
                      />
                    ) : (
                      <IoChevronUpOutline
                        className="fcdt-icon-green"
                        size={30}
                      />
                    )}
                  </div>
                  <section
                    className={style.container_select_option}>
                    {link.dataSelect && isSelect ? (
                      link.dataSelect.map((option, indexOption) => {
                        return (
                          <article key={indexOption}>
                            <LinkNav
                              key={option.url}
                              url={option.url}
                              isActive={pathname.includes(option.url)}
                              isDisabled={option.isDisabled}
                            >
                              <option.icon size={24} />
                              {option.label}
                            </LinkNav>
                          </article>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </section>
                </>
              )}
            </article>
          );
        })}
      </nav>
      <br />
      <button type="button" onClick={logOutUser}>
        <IoLogInOutline size={30} />
        Cerrar sesión
      </button>
    </aside>
  );
};

export default Sidebar;
